import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../hooks/useWindowDimensions';
import cookies from 'js-cookie';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { withRouter, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import {
    faCode,
    faCamera,
    faChartBar,
    faAngleRight,
    faVideo,
    faPuzzlePiece,
    faCalendarWeek
} from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";

import ImagePortfolio from '../content/ImagePortfolio';
import SitePortfolio from '../content/SitePortfolio';

import BlockchainSection from '../content/BlockchainSection';
import Footer from '../theme/Footer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/css/Portfolio.css';
import QuestionButton from '../content/QuestionButton';
import Button from '../content/MainButton';
import QuestionService from '../content/QuetionService';


const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 425,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        }
    ]
};

const Portfolio = () => {
    const { service } = useParams();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [old, setOld] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(null);
    // const [data, setData] = useState({});

    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = (e) => {
    //     let uuid = e.target.dataset.item;

    //     items.capabilities.forEach((item) => {
    //         if (item.id === parseInt(uuid)) {
    //             setData(item);
    //         }
    //     });

    //     setShow(true);
    // };

    const evaluateOld = useCallback(() => {
        if (old === null || old !== service) {
            setOld(service);
            setIsLoading(false);
        }
    }, [old, service, setOld, setIsLoading]);

    const translateService = useCallback(() => {
        let dataService = {};
        let serviceJson = null;

        try {
            serviceJson = require(`../../data/lang/${service}.json`);
        } catch (error) {
            window.location.href = '/#/not-found';
            window.location.reload();
        }

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                document.title = `${serviceJson.es.title} | TICONGLE Agency`;

                dataService = serviceJson.es;
                dataService.code = serviceJson.code;
                dataService.portfolio = serviceJson.portfolio;
                dataService.modal = serviceJson.modal;
                break;

            case 'en':
                document.title = `${serviceJson.en.title} | TICONGLE Agency`;

                dataService = serviceJson.en;
                dataService.code = serviceJson.code;
                dataService.portfolio = serviceJson.portfolio;
                dataService.modal = serviceJson.modal;
                break;
        }

        switch (service) {
            // case 'blockchain':      
            //     dataService.icon = faChartBar; 
            //     break; 

            case 'digital-marketing':
                dataService.icon = faChartBar;
                break;

            case 'web-development':
                dataService.icon = faCode;
                break;

            case 'app-development':
                dataService.icon = faAndroid;
                break;

            case 'photo':
                dataService.icon = faCamera;
                break;

            case 'audiovisual-production':
                dataService.icon = faVideo;
                break;

            case 'branding':
                dataService.icon = faPuzzlePiece;
                break;

            case 'online-events':
                dataService.icon = faCalendarWeek;
                break;

            default:
                break;
        }

        setIsLoading(true);
        setItems(dataService);
    }, [setIsLoading, service]);

    useEffect(() => {
        evaluateOld();

        if (isLoading === false) {
            translateService();
        }
    }, [isLoading, translateService, evaluateOld]);

    return (
        <div id="portfolio" className="h-100 p-none">
            {items && (
                <div className="h-100 p-none" >
                    <Row className={`h-100-vh bg-img-${items.code} bg-config `}>
                        <Col className="p-none bg-shadow-section text-white">
                            <Container className="h-100">
                                <div className="h-100 py-5 d-flex align-items-center justify-content-start">
                                    <h1
                                        className={`portfolio-title title-${items.code}`}
                                        data-aos="fade-right"
                                    >
                                        {items.title}
                                        <br />
                                        <span className={`text-${items.code}`}>
                                            {items.code !== 'blockchain' && (
                                                <FontAwesomeIcon icon={items.icon} />
                                            )}
                                            {items.code === 'blockchain' && (
                                                <svg version="1.1" className="svg-inline--fa" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" xmlSpace="preserve">
                                                    <g>
                                                        <path fill="currentColor" d="M104.75,99.06c11.78-5.9,22.99-11.57,34.27-17.09c1.8-0.88,1.73-2.13,1.73-3.65
                                                            c-0.02-13.12,0.09-26.25-0.07-39.37c-0.05-3.95,1.31-6.47,4.83-8.43c12.96-7.22,25.82-14.61,38.62-22.1
                                                            c3.33-1.95,6.16-1.84,9.41,0.05c12.82,7.46,25.68,14.85,38.62,22.11c3.36,1.88,4.69,4.36,4.65,8.17
                                                            c-0.14,13.12-0.06,26.25-0.04,39.37c0,1.42-0.38,2.8,1.54,3.74c11.38,5.56,22.68,11.28,34.44,17.17c0-4.66,0.09-8.86-0.03-13.06
                                                            c-0.09-3.24,1.25-5.42,4.03-7c13.44-7.62,26.86-15.29,40.26-22.99c2.52-1.45,4.88-1.52,7.41-0.07
                                                            c13.51,7.75,27.05,15.47,40.59,23.18c2.66,1.51,3.79,3.71,3.78,6.77c-0.06,15.25-0.07,30.5,0.01,45.74
                                                            c0.02,3.43-1.51,5.56-4.38,7.18c-11.72,6.62-23.37,13.37-35.11,19.96c-1.83,1.03-2.61,2.1-2.6,4.3c0.11,15.75,0.11,31.49,0,47.24
                                                            c-0.02,2.34,0.79,3.49,2.76,4.59c11.53,6.45,22.93,13.14,34.48,19.56c3.47,1.93,4.93,4.42,4.89,8.41
                                                            c-0.16,14.62-0.14,29.25-0.01,43.87c0.04,3.81-1.28,6.3-4.63,8.18c-12.94,7.26-25.82,14.62-38.63,22.1c-3.43,2-6.31,1.9-9.73-0.12
                                                            c-11.27-6.65-22.73-12.98-34.03-19.57c-2.13-1.24-3.58-1.19-5.68,0.15c-12.29,7.86-24.69,15.56-37.12,23.2
                                                            c-1.72,1.05-2.37,2.15-2.28,4.14c0.17,3.74,0.01,7.5,0.06,11.25c0.04,2.77-1.12,4.8-3.5,6.16c-13.74,7.86-27.49,15.72-41.23,23.57
                                                            c-2.26,1.29-4.5,1.22-6.75-0.07c-13.73-7.88-27.47-15.74-41.21-23.6c-2.27-1.3-3.35-3.26-3.32-5.86c0.01-0.5,0-1,0-1.5
                                                            c-0.07-4.24,1.19-9.19-0.59-12.5c-1.7-3.16-6.57-4.62-10.05-6.8c-9.62-6.02-19.29-11.96-28.84-18.1c-1.97-1.26-3.39-1.26-5.37-0.11
                                                            c-11.32,6.57-22.77,12.92-34.05,19.57c-3.52,2.07-6.46,2.23-10.05,0.11c-12.67-7.47-25.45-14.75-38.29-21.93
                                                            c-3.33-1.86-4.85-4.19-4.81-8.05c0.13-14.87,0.12-29.75,0.01-44.62c-0.03-3.53,1.3-5.84,4.36-7.56
                                                            c11.63-6.53,23.16-13.24,34.79-19.77c2.05-1.15,2.99-2.31,2.97-4.85c-0.15-15.62-0.13-31.24-0.02-46.87
                                                            c0.02-2.31-0.71-3.49-2.71-4.62c-11.64-6.51-23.15-13.27-34.79-19.78c-3.2-1.79-4.63-4.1-4.6-7.79c0.12-14.87,0.12-29.75,0-44.62
                                                            c-0.03-3.7,1.4-6.01,4.6-7.8c13.04-7.32,26.03-14.75,38.94-22.29c3.19-1.86,5.95-1.85,9.08-0.03c12.94,7.5,25.91,14.95,38.94,22.29
                                                            c3.19,1.8,4.7,4.16,4.53,7.85C104.61,90.47,104.75,94.48,104.75,99.06z M140.75,307.92c0-6.29,0.14-11.79-0.05-17.29
                                                            c-0.13-3.84,1.39-6.21,4.75-8.08c11.66-6.48,23.16-13.24,34.79-19.78c1.85-1.04,2.63-2.15,2.56-4.33
                                                            c-0.17-5.74-0.19-11.5,0.01-17.25c0.08-2.36-0.81-3.48-2.78-4.58c-11.64-6.52-23.15-13.26-34.79-19.78
                                                            c-3.22-1.8-4.56-4.16-4.53-7.84c0.12-14.75,0.13-29.5,0-44.25c-0.03-3.8,1.27-6.26,4.65-8.14c11.76-6.55,23.38-13.36,35.1-19.99
                                                            c1.56-0.88,2.39-1.73,2.35-3.68c-0.14-6.12-0.11-12.25-0.02-18.37c0.03-1.7-0.5-2.68-2.01-3.53
                                                            c-10.76-6.05-21.48-12.17-32.17-18.34c-1.43-0.83-2.56-0.93-4.07-0.16c-12.55,6.36-25.12,12.68-37.74,18.89
                                                            c-1.62,0.8-2.11,1.72-2.08,3.43c0.1,5.12-0.17,10.26,0.1,15.37c0.23,4.29-1.42,6.97-5.15,9.04c-11.45,6.34-22.75,12.96-34.17,19.35
                                                            c-1.95,1.09-2.82,2.2-2.8,4.57c0.12,15.75,0.11,31.5,0.01,47.25c-0.01,2.16,0.7,3.28,2.56,4.32c11.53,6.46,22.94,13.14,34.48,19.56
                                                            c3.57,1.99,5.15,4.53,5.08,8.69c-0.2,12.25,0.15,24.5-0.19,36.74c-0.12,4.29,1.19,6.62,4.88,8.78
                                                            C119.9,294.62,130.01,301.2,140.75,307.92z M236.75,307.93c11.5-7.18,22.43-14.08,33.45-20.83c1.94-1.19,2.62-2.46,2.6-4.74
                                                            c-0.12-13.12,0.06-26.25-0.13-39.37c-0.06-4.16,1.5-6.67,5.1-8.66c11.56-6.41,22.95-13.12,34.47-19.58
                                                            c1.87-1.05,2.56-2.17,2.55-4.33c-0.1-15.75-0.11-31.5,0.01-47.25c0.02-2.36-0.83-3.47-2.8-4.57
                                                            c-11.64-6.52-23.15-13.26-34.79-19.77c-3.22-1.8-4.64-4.17-4.52-7.85c0.18-5.24-0.06-10.5,0.1-15.75c0.06-2.04-0.55-3.12-2.4-4.03
                                                            c-12.42-6.1-24.79-12.28-37.14-18.52c-1.59-0.8-2.73-0.93-4.39,0.03c-10.67,6.21-21.4,12.3-32.17,18.34
                                                            c-1.53,0.86-2.01,1.84-1.99,3.53c0.09,5.87,0.16,11.75-0.03,17.62c-0.07,2.31,0.68,3.51,2.69,4.63c11.43,6.38,22.73,13,34.17,19.35
                                                            c3.69,2.04,5.34,4.64,5.27,8.97c-0.21,14.37-0.21,28.75,0,43.12c0.06,4.32-1.51,6.95-5.21,9.01
                                                            c-11.55,6.42-22.96,13.09-34.49,19.56c-1.73,0.97-2.5,1.94-2.44,4.01c0.16,5.87,0.17,11.75,0,17.62c-0.07,2.21,0.78,3.26,2.61,4.28
                                                            c11.74,6.59,23.38,13.36,35.11,19.97c3.05,1.72,4.48,4.03,4.38,7.57C236.64,295.91,236.75,301.54,236.75,307.93z M158.88,288.73
                                                            c9.91,5.69,19.28,11.04,28.61,16.46c1.58,0.92,2.59-0.08,3.73-0.73c6.6-3.75,13.18-7.53,19.77-11.31c2.41-1.39,4.82-2.79,7.63-4.42
                                                            c-9.76-5.59-18.94-10.81-28.08-16.11c-1.4-0.81-2.42-0.62-3.75,0.15C177.75,278.01,168.65,283.16,158.88,288.73z M224.75,47.14
                                                            c-9.77,5.56-18.96,10.77-28.13,16.01c-0.93,0.53-1.91,0.93-1.9,2.42c0.07,10.79,0.04,21.57,0.04,32.87
                                                            c9.86-5.64,19.2-11.03,28.6-16.33c1.2-0.68,1.41-1.5,1.41-2.7C224.74,68.83,224.75,58.25,224.75,47.14z M50.75,146.38
                                                            c0-11.11-0.06-21.69,0.05-32.27c0.02-1.96-1.12-2.51-2.42-3.25c-6.93-3.93-13.82-7.94-20.77-11.83c-2.19-1.23-4.2-2.87-6.86-3.55
                                                            c0,10.63,0.02,20.99-0.03,31.35c-0.01,1.62,0.3,2.7,1.88,3.58C31.84,135.55,41,140.83,50.75,146.38z M284.75,95.08
                                                            c0,11.22,0.04,21.91-0.04,32.59c-0.01,1.63,0.94,2.12,2.01,2.74c4.11,2.34,8.22,4.68,12.33,7.02c5.13,2.92,10.27,5.85,15.69,8.94
                                                            c0-11.02,0-21.5-0.01-31.98c0-1.08,0.18-2.1-1.15-2.85C304.09,106.19,294.65,100.74,284.75,95.08z M182.75,224.4
                                                            c0-11.14-0.01-21.72,0.02-32.31c0-1.21-0.23-2.03-1.42-2.7c-9.4-5.3-18.75-10.69-28.59-16.32c0,11.3,0.02,22.1-0.03,32.9
                                                            c-0.01,1.27,0.73,1.7,1.61,2.2C163.62,213.47,172.9,218.77,182.75,224.4z M50.75,302.4c0-11.24-0.04-21.93,0.04-32.62
                                                            c0.01-1.63-0.97-2.12-2.04-2.73c-5.73-3.27-11.46-6.54-17.19-9.8c-3.5-1.99-7.01-3.98-10.81-6.13c0,11.06,0,21.53,0.01,32.01
                                                            c0,1.08-0.15,2.08,1.17,2.82C31.43,291.3,40.87,296.75,50.75,302.4z M284.75,251.07c0,11.15,0,21.63,0,32.11
                                                            c0,1.1-0.1,2.06,1.22,2.8c9.5,5.33,18.93,10.79,28.78,16.42c0-11.14-0.01-21.72,0.01-32.29c0-1.2-0.2-2.03-1.41-2.71
                                                            C303.96,262.1,294.61,256.72,284.75,251.07z M182.75,350.38c0-11.23-0.04-21.92,0.04-32.62c0.01-1.86-1.26-2.21-2.4-2.87
                                                            c-6.26-3.59-12.53-7.17-18.8-10.75c-2.84-1.62-5.68-3.24-8.84-5.03c0,11.11,0.04,21.59-0.04,32.06c-0.01,1.6,0.53,2.46,1.87,3.21
                                                            C163.79,339.56,172.96,344.81,182.75,350.38z M26.88,84.74c9.85,5.64,19.14,10.89,28.35,16.28c1.72,1.01,2.83,0.1,4.08-0.61
                                                            c8.22-4.68,16.43-9.38,24.61-14.11c0.77-0.45,2-0.53,2.03-1.96c-9.05-5.17-18.14-10.33-27.18-15.56c-1.3-0.75-2.31-1-3.75-0.16
                                                            C45.89,73.93,36.7,79.14,26.88,84.74z M290.89,84.75c9.88,5.65,19.15,10.95,28.41,16.28c0.96,0.55,1.79,0.67,2.77,0.11
                                                            c9.34-5.37,18.69-10.73,28.56-16.38c-9.86-5.65-19.21-11.01-28.55-16.38c-0.98-0.57-1.82-0.46-2.77,0.1
                                                            C310.05,73.8,300.77,79.09,290.89,84.75z M62.75,146.41c6.67-3.8,12.91-7.34,19.13-10.91c3.56-2.04,8.52-3.32,10.25-6.4
                                                            c1.82-3.25,0.59-8.24,0.61-12.47c0.04-7.02,0.01-14.04,0.01-21.51c-9.82,5.6-19.09,10.91-28.38,16.17
                                                            c-1.06,0.6-1.65,1.22-1.64,2.57C62.78,124.54,62.75,135.22,62.75,146.41z M224.75,173.12c-9.85,5.62-19.12,10.92-28.41,16.2
                                                            c-0.88,0.5-1.62,0.94-1.61,2.2c0.05,10.8,0.03,21.59,0.03,32.91c9.83-5.62,19.17-11,28.56-16.3c1.18-0.66,1.45-1.46,1.45-2.68
                                                            C224.73,194.86,224.75,184.27,224.75,173.12z M194.75,349.79c0.4,0.14,0.55,0.25,0.63,0.21c6.49-3.68,12.7-8.05,19.54-10.9
                                                            c8.26-3.43,11.04-8.64,10.01-17.39c-0.86-7.3-0.18-14.78-0.18-22.61c-9.81,5.6-19.08,10.91-28.38,16.17
                                                            c-1.05,0.6-1.66,1.2-1.65,2.56C194.78,328.52,194.75,339.21,194.75,349.79z M218.65,36.75c-9.97-5.7-19.43-11.1-28.88-16.52
                                                            c-1.25-0.72-2.11,0.1-3.06,0.64c-5.08,2.89-10.17,5.78-15.25,8.68c-4.04,2.31-8.07,4.65-12.53,7.22
                                                            c9.83,5.63,19.1,10.92,28.35,16.25c0.95,0.55,1.78,0.71,2.77,0.14C199.39,47.77,208.75,42.42,218.65,36.75z M218.65,162.75
                                                            c-8.29-4.74-16.17-8.78-23.51-13.63c-4.59-3.04-8.07-2.98-12.63-0.03c-7.46,4.83-15.39,8.95-23.6,13.65
                                                            c9.88,5.67,19.24,11.03,28.58,16.41c1.01,0.58,1.85,0.4,2.78-0.14C199.53,173.7,208.79,168.4,218.65,162.75z M26.89,240.73
                                                            c9.91,5.69,19.28,11.04,28.61,16.44c1.57,0.91,2.61-0.1,3.75-0.74c7.58-4.3,15.14-8.62,22.68-12.98c1.43-0.83,3.11-1.37,4.3-2.95
                                                            c-9.4-5.36-18.6-10.57-27.74-15.87c-1.42-0.82-2.43-0.6-3.74,0.16C45.72,230.01,36.63,235.17,26.89,240.73z M290.9,240.75
                                                            c8.18,4.69,15.94,8.7,23.21,13.45c4.76,3.11,8.47,3.24,13.29,0.04c7.3-4.84,15.14-8.86,23.23-13.5
                                                            c-9.91-5.68-19.28-11.03-28.63-16.41c-1.01-0.58-1.84-0.38-2.77,0.16C309.99,229.82,300.72,235.12,290.9,240.75z M182.75,98.39
                                                            c0-11.05,0-21.52-0.01-31.99c0-1.08,0.19-2.11-1.13-2.85c-9.5-5.35-18.95-10.8-28.85-16.47c0,11.2,0.03,21.88-0.03,32.56
                                                            c-0.01,1.35,0.59,1.97,1.65,2.57C163.66,87.48,172.92,92.78,182.75,98.39z M356.75,95.12c-9.16,5.22-17.83,10.3-26.64,15.09
                                                            c-2.66,1.44-3.54,3.14-3.42,6.09c0.24,5.85,0.07,11.73,0.07,17.59c0,4.03,0,8.05,0,12.52c9.84-5.62,19.12-10.91,28.4-16.21
                                                            c0.87-0.5,1.63-0.94,1.63-2.21C356.73,117.2,356.75,106.4,356.75,95.12z M62.75,302.39c7.09-4.04,13.49-8.47,20.5-11.46
                                                            c7.94-3.38,10.61-8.4,9.67-16.84c-0.83-7.43-0.17-15.03-0.17-22.99c-9.81,5.6-19.08,10.9-28.38,16.17c-1.05,0.6-1.66,1.2-1.65,2.56
                                                            C62.78,280.52,62.75,291.21,62.75,302.39z M326.75,302.42c9.84-5.63,19.18-11.03,28.59-16.3c1.62-0.91,1.4-2.14,1.4-3.46
                                                            c0.01-5.99,0.01-11.98,0.01-17.97c0-4.39,0-8.79,0-13.57c-9.75,5.55-18.9,10.82-28.13,15.96c-1.58,0.88-1.9,1.96-1.89,3.58
                                                            C326.78,281.01,326.75,291.36,326.75,302.42z"/>
                                                    </g>
                                                </svg>
                                            )}
                                        </span>
                                    </h1>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                    <Row id="portfolio-details" className={`bg-img-description-${items.code} bg-config `}>
                        <Col className="p-none bg-shadow-section text-white pt-5 pb-5">
                            <Container className="h-100">
                                <Row className="m-none h-100">
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="p-none" >
                                        <Container className="h-100">
                                            <div className="h-100 d-flex align-items-center justify-content-center">
                                                <div className="text-center" data-aos="fade-right">
                                                    <h1 className="description-title-section">{t('what-do')}</h1>
                                                    <br />
                                                    {/* /////////////////////////// */}
                                                    <p
                                                        className="description-title text-justify px-3"
                                                        dangerouslySetInnerHTML={{ __html: items.description }}
                                                    ></p>
                                                    <div className='d-none d-xl-block d-lg-block'>
                                                    <QuestionService></QuestionService>
                                                    </div>
                                                    {/* <QuestionButton
                                                        text={"¡Hablemos!"}
                                                        className={"btn-home"}
                                                        openInNewTab={true} /> */}
                                                </div>
                                            </div>
                                        </Container>
                                    </Col>

                                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="p-none" >
                                        <Container className="h-100">
                                            <div className="h-100 d-flex align-items-center justify-content-start">
                                                <div className="text-left w-100">
                                                    <ul className="capabilities-list">
                                                        {items.capabilities.map((item, index) => {
                                                            return (
                                                                <li key={index} className={`border-${items.code}`} data-aos="fade-up">
                                                                    <span className={`text-${items.code}`}>
                                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                                    </span>
                                                                    &nbsp;
                                                                    <span className="text-white">{item.capability}</span>
                                                                    {/* { items.modal === true && (
                                                                        <button data-item={ item.id } className="btn-capability btn-modal" onClick={ handleShow } >{ item.capability }</button>
                                                                    ) }
                                                                    { items.modal === false && (
                                                                        <span className="text-white">{ item.capability }</span>
                                                                    ) } */}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Container>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="p-none d-sm-block d-md-block d-lg-none">
                                        <Container>
                                        <QuestionService></QuestionService>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    {items.code === 'blockchain' && (
                        <BlockchainSection />
                    )}
                    {(items.code !== "online-events" && items.code !== "blockchain") && (
                        <Row className="bg-service-portfolio" >
                            <Col className="p-none pt-5 pb-5 bg-shadow-service text-white"> 
                                <Container className="h-100">
                                    <div className="h-100 d-flex align-items-center">
                                        {(width <= 375) && (
                                            <div className="w-100 pt-3 pb-3 container" data-aos="fade-up" id="mobile-portfolio">
                                                <Slider {...settings}>
                                                    {items.portfolio.map((project) => {
                                                        if (project.status === 1) {
                                                            return (
                                                                <div key={project.key}>
                                                                    <div className="mx-2 ">
                                                                        <Card className="card-portfolio">
                                                                            {project.type === 'site' ? (
                                                                                <SitePortfolio
                                                                                    data={project}
                                                                                    service={items.code}
                                                                                />
                                                                            ) : (
                                                                                <ImagePortfolio
                                                                                    data={project}
                                                                                    service={items.code}
                                                                                />
                                                                            )}
                                                                        </Card>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </Slider>
                                            </div>
                                        )}
                                        {(width > 375) && (
                                            <Container className="w-100" data-aos="fade-up" id="desktop-portfolio">
                                                <Slider className="container" {...settings}>
                                                    {items.portfolio.map((project) => {
                                                        if (project.status === 1) {
                                                            return (
                                                                <div key={project.key}>
                                                                    <Container>
                                                                        <Card className="card-portfolio">
                                                                            {project.type === 'site' ? (
                                                                                <SitePortfolio
                                                                                    data={project}
                                                                                    service={items.code}
                                                                                />
                                                                            ) : (
                                                                                <ImagePortfolio
                                                                                    data={project}
                                                                                    service={items.code}
                                                                                />
                                                                            )}
                                                                        </Card>
                                                                    </Container>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </Slider>
                                            </Container>
                                        )}
                                    </div>
                                </Container>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="p-none">
                            <Footer />
                        </Col>
                    </Row>
                    {/* <Modal show={ show } size="lg" onHide={ handleClose } backdrop="static" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{ data.capability }</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            { data.description }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleClose }>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal> */}
                </div>
            )}
        </div>
    );
}

export default withRouter(Portfolio);


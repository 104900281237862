import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from '../hooks/useWindowDimensions';
import ShowMoreText from "react-show-more-text";

import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import Footer from '../../components/theme/Footer';

import ReviewsJson from '../../data/reviews.json';

import '../../assets/css/Reviews.css';
import QuestionButton from '../content/QuestionButton';

const Reviews = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [isLoading, setIsLoading] = useState(false);
    const [reviewsData, setReviewsData] = useState(null);

    const loadData = useCallback(() => {
        let dataComments = [];

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                ReviewsJson.forEach((item) => {
                    dataComments.push({
                        name: item.name,
                        photo: item.photo,
                        colorized: item.colorized,
                        comment: item.es.comment,
                        position: item.es.position,
                    });
                });
                break;

            case 'en':
                ReviewsJson.forEach((item) => {
                    dataComments.push({
                        name: item.name,
                        photo: item.photo,
                        colorized: item.colorized,
                        comment: item.en.comment,
                        position: item.en.position,
                    });
                });
                break;
        }

        document.title = `${t('reviews-title')} | TICONGLE Agency`;
        // console.log(dataComments);
        setReviewsData(dataComments);
        setIsLoading(true);
    }, [t]);

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData]);

    return (
        <div id="Reviews" className="h-100 p-none">
            <Row className="bg-img-review h-100-vh">
                <Col className="p-none bg-shadow text-white">
                    <Container className="h-100">
                        <div className="h-100 py-5 d-flex align-items-center justify-content-start">
                            <h1 className="portfolio-title" data-aos="fade-right">
                                {t('reviews-title')}
                            </h1>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="bg-service-portfolio" >
                <Col className="p-none bg-shadow-service text-white">
                    <Container className="h-100 py-5">
                        <Row>
                            <Col xl={7} lg={8} md={12} sm={12} xs={12}>
                                <h2 className="fw-bold" data-aos="fade-right">{t('reviews-text')}</h2>
                                <br />
                                <p className="text-justify" data-aos="fade-right">{t('reviews-description')}</p>
                            </Col>
                        </Row>
                        <br />
                        {reviewsData && (
                            <Row>
                                {reviewsData.map((item, index) => {
                                    return (
                                        <Col key={index} className="testimonial-box" xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <div
                                                className={`testimonial-card ${(item.colorized && width > 991) ? 'testimonial-colorized' : ''} ${(((index + 1) % 2) === 0 && width < 992) ? 'testimonial-colorized' : ''}`}
                                                data-aos="fade-up"
                                            >
                                                <Row className="h-100">
                                                    <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                                                        <div className="h-100 d-flex align-items-center justify-content-center">
                                                            <FontAwesomeIcon className="testimonial-icon" icon={faQuoteRight} size="4x" />
                                                        </div>
                                                    </Col>
                                                    <Col xl={8} lg={8} md={12} sm={12} xs={12} >
                                                        <div className="testimonial-content">
                                                            <div className="box d-flex align-items-center h-100 w-100">
                                                                <p>
                                                                    <ShowMoreText
                                                                        lines={3}
                                                                        more={t('show-more')}
                                                                        less={t('show-less')}
                                                                    // width="300"
                                                                    >
                                                                        {item.comment}
                                                                    </ShowMoreText>
                                                                    {/* { item.comment.length > 100 ? <><br/><a className="testimonials-see-more" >Ver más</a></> : "" } */}
                                                                </p>
                                                            </div>
                                                            <span className="d-block fw-bold">
                                                                {item.name}
                                                            </span>
                                                            {/* <span className="d-block">
                                                                { item.position }
                                                            </span> */}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                        <div className='review-btn'>
                    <QuestionButton
                        text={"¡Contáctanos!"}
                        className={"btn-home"}
                        openInNewTab={true} />
                        </div>
                    </Container>
                    
                </Col>
            </Row>
            <Row>
                <Col className="p-none">
                    <Footer />
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(Reviews);

import Button from '../../components/content/MainButton'

function QuestionService() {
    return (
        <div className='question-service '>
            <p className='text-center question-text'>¿Te ayudamos a potenciar tu negocio?</p>
            <Button
                text={"¡Hablemos!"}
                className={"btn-service"}
                openInNewTab={true}
                icon={false}
            />
        </div>
    );
}

export default QuestionService;